@import './_override.less';
@import './variables.less';
.mu-account-operate-form {
  --rv-cell-vertical-padding: 9px;
  --rv-cell-horizontal-padding: 18px;
  &--heading {
    font-size: 34px;
    margin: 75px 0;
    text-align: center;
  }
  .rv-cell::after {
    content: unset;
  }

  .rv-cell {
    // background-color: white;
    border-radius: 100px;
    border: 1px solid #c2cbd6;
    // padding: 10px 18px 10px 28px;
    margin-bottom: 28px;
  }

  &--required {
    .rv-cell {
      padding-left: 28px;
    }
    .rv-cell::before {
      left: var(--rv-padding-md);
    }
  }

  .rv-field .rv-field__suffix {
    line-height: 1;

    .rv-button {
      width: 72px;
      height: 24px;
    }
  }
}

.mu-form {
  --rv-cell-background-color: transparent;
  --rv-cell-border-color: var(--rv-gray-4);
  --rv-cell-horizontal-padding: 0;
  --rv-cell-vertical-padding: 0;
  .rv-cell {
    // padding-top: var(--rv-padding-md);
    &.rv-cell--required {
      &::before {
        content: unset;
      }
      .rv-cell__title::after {
        content: '*';
        display: inline;
        margin-left: 2px;
        color: var(--mu-active-color);
      }
    }
  }

  &&--vertical {
    .rv-cell {
      &::after {
        content: unset;
      }
    }
    .rv-field__children {
      background-color: white;
      border-radius: 4px;
      border: 1px solid #c2cbd6;
      padding: 10px;
      --rv-divider-border-color: var(--mu-border-color);
    }
  }
}

// https://css-tricks.com/flexbox-truncated-text/
.mu-reset-min-width {
  min-width: 0;
}

.mu-panel__title {
  &::before {
    content: '';
    display: inline-block;
    background: #0757af;
    border-radius: 2px;
    height: 18px;
    width: 4px;
    vertical-align: middle;
    margin-right: 10px;
  }
}
