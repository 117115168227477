:root {
  --mu-primary-color: #0757af;
  --mu-success-color: #00c081;
  --mu-success-color-bg: #e9f6dc;
  --mu-warning-color: #ffb800;
  --mu-secondary-color: #8592a6;
  --mu-active-color: #ff0264;

  --mu-text-color: #2a2a2c;
  --mu-title-color: #242830;
  --mu-border-color: #dadfe6;
  --mu-background-color: #fafafa;

  --mu-rating-star-color: #ffc039;

  --mu-border-radius: 4px;

  --mu-search-button-color: rgba(255, 57, 92, 1);
  --mu-search-button-color-shadow: #fd8a9a;
  --mu-search-button-color-hover: lighten(rgba(255, 57, 92, 1), 5%);
  --mu-reserve-button-color: #f56f6b;
  --mu-reserve-button-color-hover: lighten(#f56f6b, 5%);
  --mu-soldout-button-color: #fff6f6;

  --mu-price-primary-color: #51a9de;
}
