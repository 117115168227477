/* You can add global styles to this file, and also import other style files */
*,
:after,
:before {
  box-sizing: border-box;
}
body {
  font-size: 14px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
a {
  color: var(--mu-primary-color);
}
:root {
  --mu-font-size-sm: 12px;
  --mu-font-size: 14px;
  --mu-font-size-lg: 16px;
  --mu-font-size-xl: 18px;
}
:root:root {
  --rv-brand-color: #285ca5 !important;
  --rv-primary-color: var(--mu-primary-color);
  --rv-success-color: var(--mu-success-color);
  --rv-warning-color: var(--mu-warning-color);
  --rv-blue: var(--rv-brand-color) !important;
  --rv-divider-margin: 12px 0 !important;
  --rv-cell-group-inset-padding: var(--mu-panel-inset-padding) !important;
  --rv-cell-group-inset-border-radius: var(--mu-panel-border-radius);
  --rv-cell-vertical-padding: var(--mu-panel-padding-v);
  --rv-cell-horizontal-padding: var(--mu-panel-padding-h);
  --rv-button-border-radius: var(--mu-border-radius);
  --rv-button-default-height: 40px;
  --rv-step-active-color: var(--mu-primary-color);
  --rv-step-finish-line-color: var(--mu-primary-color);
  --rv-typography-secondary-color: var(--mu-secondary-color);
  --rv-slider-bar-height: 2px;
  --rv-slider-button-box-shadow: none;
  --rv-slider-button-width: 30px;
  --rv-slider-button-height: 30px;
  --rv-slider-button-box-shadow: 0px 3px 7px 0px #d0d3e2;
  --rv-slider-active-background-color: var(--mu-primary-color);
  --rv-dropdown-menu-title-active-text-color: var(--mu-primary-color);
  --rv-dropdown-menu-option-active-color: var(--mu-primary-color);
  --rv-dropdown-menu-title-text-color: var(--mu-title-color);
  --rv-notice-bar-text-color: #eb9362;
  --rv-cell-group-title-color: #8292a8;
  --rv-index-anchor-text-color: var(--rv-cell-group-title-color);
  --rv-index-anchor-font-weight: normal;
  --rv-index-bar-index-font-size: 12px;
  --rv-index-bar-index-line-height: 18px;
  --rv-index-bar-index-active-color: var(--mu-success-color);
  --rv-index-anchor-sticky-text-color: var(--mu-success-color);
}
:root:root .rv-field__tooltip {
  line-height: 1;
}
:root:root .rv-dropdown-menu__item {
  position: relative;
}
:root:root .rv-dropdown-menu__item + :root:root .rv-dropdown-menu__item:before {
  content: '';
  display: block;
  width: 1px;
  height: 31px;
  border-left: 1px solid #d5d9e2;
  left: -1px;
  position: absolute;
}
:root:root .rv-dropdown-menu__title {
  font-weight: 600;
  --rv-dropdown-menu-title-line-height: 1;
}
:root:root .rv-dropdown-menu__title::after {
  content: none !important;
}
:root:root .rv-dropdown-menu__title:root:root .rv-dropdown-menu__title--active::after {
  content: '';
  top: unset;
  bottom: -13px;
  width: 100%;
  border-bottom: 1px solid;
  border-color: #3264ff;
  transform: unset;
}
:root:root .rv-index-bar > .rv-cell {
  padding-right: 30px;
}
:root:root .rv-index-bar > .rv-cell::after {
  right: 30px;
}
:root:root .rv-cell-group.rv-cell-group--inset {
  box-shadow: var(--mu-panel-inset-shadow);
}
:root {
  --mu-primary-color: #0757af;
  --mu-success-color: #00c081;
  --mu-success-color-bg: #e9f6dc;
  --mu-warning-color: #ffb800;
  --mu-secondary-color: #8592a6;
  --mu-active-color: #ff0264;
  --mu-text-color: #2a2a2c;
  --mu-title-color: #242830;
  --mu-border-color: #dadfe6;
  --mu-background-color: #fafafa;
  --mu-rating-star-color: #ffc039;
  --mu-border-radius: 4px;
  --mu-search-button-color: #ff395c;
  --mu-search-button-color-shadow: #fd8a9a;
  --mu-search-button-color-hover: #ff5371;
  --mu-reserve-button-color: #f56f6b;
  --mu-reserve-button-color-hover: #f78683;
  --mu-soldout-button-color: #fff6f6;
  --mu-price-primary-color: #51a9de;
}
.mu-account-operate-form {
  --rv-cell-vertical-padding: 9px;
  --rv-cell-horizontal-padding: 18px;
}
.mu-account-operate-form--heading {
  font-size: 34px;
  margin: 75px 0;
  text-align: center;
}
.mu-account-operate-form .rv-cell::after {
  content: unset;
}
.mu-account-operate-form .rv-cell {
  border-radius: 100px;
  border: 1px solid #c2cbd6;
  margin-bottom: 28px;
}
.mu-account-operate-form--required .rv-cell {
  padding-left: 28px;
}
.mu-account-operate-form--required .rv-cell::before {
  left: var(--rv-padding-md);
}
.mu-account-operate-form .rv-field .rv-field__suffix {
  line-height: 1;
}
.mu-account-operate-form .rv-field .rv-field__suffix .rv-button {
  width: 72px;
  height: 24px;
}
.mu-form {
  --rv-cell-background-color: transparent;
  --rv-cell-border-color: var(--rv-gray-4);
  --rv-cell-horizontal-padding: 0;
  --rv-cell-vertical-padding: 0;
}
.mu-form .rv-cell.rv-cell--required::before {
  content: unset;
}
.mu-form .rv-cell.rv-cell--required .rv-cell__title::after {
  content: '*';
  display: inline;
  margin-left: 2px;
  color: var(--mu-active-color);
}
.mu-form.mu-form--vertical .rv-cell::after {
  content: unset;
}
.mu-form.mu-form--vertical .rv-field__children {
  background-color: white;
  border-radius: 4px;
  border: 1px solid #c2cbd6;
  padding: 10px;
  --rv-divider-border-color: var(--mu-border-color);
}
.mu-reset-min-width {
  min-width: 0;
}
.mu-panel__title::before {
  content: '';
  display: inline-block;
  background: #0757af;
  border-radius: 2px;
  height: 18px;
  width: 4px;
  vertical-align: middle;
  margin-right: 10px;
}
