*,
:after,
:before {
  box-sizing: border-box;
}
body {
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

a {
  color: var(--mu-primary-color);
}

:root {
  --mu-font-size-sm: 12px;
  --mu-font-size: 14px;
  --mu-font-size-lg: 16px;
  --mu-font-size-xl: 18px;
}
