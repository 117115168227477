:root:root {
  --rv-brand-color: #285ca5 !important;
  --rv-primary-color: var(--mu-primary-color);
  --rv-success-color: var(--mu-success-color);
  --rv-warning-color: var(--mu-warning-color);
  --rv-blue: var(--rv-brand-color) !important;
  // --max-layout-content-width: 768px;
  --rv-divider-margin: 12px 0 !important;
  --rv-cell-group-inset-padding: var(--mu-panel-inset-padding) !important;
  --rv-cell-group-inset-border-radius: var(--mu-panel-border-radius);
  --rv-cell-vertical-padding: var(--mu-panel-padding-v);
  --rv-cell-horizontal-padding: var(--mu-panel-padding-h);

  // --rv-calendar-range-edge-background-color: var(--rv-brand-color);
  // --rv-calendar-range-middle-color: var(--rv-brand-color);
  --rv-button-border-radius: var(--mu-border-radius);
  --rv-button-default-height: 40px;

  --rv-step-active-color: var(--mu-primary-color);
  --rv-step-finish-line-color: var(--mu-primary-color);
  --rv-typography-secondary-color: var(--mu-secondary-color);

  --rv-slider-bar-height: 2px;
  --rv-slider-button-box-shadow: none;
  --rv-slider-button-width: 30px;
  --rv-slider-button-height: 30px;
  --rv-slider-button-box-shadow: 0px 3px 7px 0px #d0d3e2;
  --rv-slider-active-background-color: var(--mu-primary-color);
  --rv-dropdown-menu-title-active-text-color: var(--mu-primary-color);
  --rv-dropdown-menu-option-active-color: var(--mu-primary-color);
  --rv-dropdown-menu-title-text-color: var(--mu-title-color);

  --rv-notice-bar-text-color: #eb9362;
  --rv-cell-group-title-color: #8292a8;
  --rv-index-anchor-text-color: var(--rv-cell-group-title-color);
  --rv-index-anchor-font-weight: normal;
  --rv-index-bar-index-font-size: 12px;
  --rv-index-bar-index-line-height: 18px;
  --rv-index-bar-index-active-color: var(--mu-success-color);
  --rv-index-anchor-sticky-text-color: var(--mu-success-color);

  .rv-field {
    &__tooltip {
      line-height: 1;
    }
  }

  .rv-dropdown-menu {
    &__item {
      position: relative;
    }

    &__item + &__item:before {
      content: '';
      display: block;
      width: 1px;
      height: 31px;
      border-left: 1px solid #d5d9e2;
      left: -1px;
      position: absolute;
    }

    &__title {
      font-weight: 600;
      --rv-dropdown-menu-title-line-height: 1;
      &::after {
        content: none !important;
      }

      &&--active::after {
        content: '';
        top: unset;
        bottom: -13px;
        width: 100%;
        border-bottom: 1px solid;
        border-color: #3264ff;
        transform: unset;
      }
    }
  }
  .rv-index-bar {
    & > .rv-cell {
      padding-right: 30px;
      &::after {
        right: 30px;
      }
    }
  }

  .rv-cell-group.rv-cell-group--inset {
    box-shadow: var(--mu-panel-inset-shadow);
  }
}
